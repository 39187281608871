import { memo, useState } from 'react';
import {
  Modal, Form, Input, Button, Select,
} from 'antd';
import { useCreateProjectMutation, CreateProjectInput, useLocalesQuery } from '../generated/graphql';
import { gql } from '@apollo/client';

type Values = {
  name: string;
  format: string;
  defaultLocale: string;
  description: string;
  link: string;
};

const CreateProjectModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { data: { locales } = {} } = useLocalesQuery();
  const [createProject, { loading, error }] = useCreateProjectMutation({
    update(cache, { data }) {
      if (!data) return;
      const { createProject } = data;
      cache.modify({
        fields: {
          projects(existingProjects = []) {
            const newProjectRef = cache.writeFragment({
              data: createProject,
              fragment: gql`
                fragment NewProject on Project {
                  id
                  name
                  description
                  link
                  defaultLocale
                  locales {
                    id
                    name
                  }
                }
              `
            });
            return [...existingProjects, newProjectRef];
          }
        }
      });
    }
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: Values) => {
    const defaultLocale = locales?.find(({ id }) => id === values.defaultLocale) || locales?.[0];

    if (!defaultLocale) {
      return;
    }

    const input: CreateProjectInput ={
      name: values.name,
      defaultLocale: defaultLocale.code,
      locales: [defaultLocale.code],
      description: values.description,
      link: values.link,
    };
    await createProject({ variables: { input } });
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Create project
      </Button>
      <Modal
        title="Create project"
        open={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        okButtonProps={{ loading }}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Project name"
            name="name"
            rules={[{ required: true, message: 'Please input project name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false, message: 'Short project description' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Project link"
            name="link"
            rules={[{ required: false, message: 'https://...' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="defaultLocale" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Select
              placeholder="Select default language"
              options={locales?.map(({ id, name }) => ({ label: name, value: id }))}
            />
          </Form.Item>
        </Form>
        {error && <p>{error.message}</p>}
      </Modal>
    </>
  );
};

export default memo(CreateProjectModal);
