import Link from 'next/link';
import { useSession, getSession } from "next-auth/react"
import {
  Typography,
  Row,
  Col,
  Card,
  Tag,
  Space,
  Descriptions,
  Button,
  Empty,
} from 'antd';

import CreateProjectModal from '../components/CreateProjectModal';
import styles from '../styles/Home.module.css';
import { GetServerSideProps } from 'next';

import { useProjectsQuery } from '../generated/graphql';
import Loading from '../components/Loading';
import { useRouter } from 'next/router';

const { Title, Text } = Typography;

export default function Home() {
  const router = useRouter();
  const { data, loading } = useProjectsQuery();
  const { data: session, status } = useSession();
  const isSessionLoading = status === "loading";

  if (status === "unauthenticated") {
    router.push('/signin');
    return null;
  }

  if (loading || !data || isSessionLoading) {
    return <Loading />;
  }

  const { projects } = data;

  if (projects.length === 0) {
    return (
      <div className={styles.container}>
        <main className={styles.main}>
          <Space size="middle" direction="vertical" align="center">
            <Empty
              description={
                <Text>
                  You do not have any projects yet.
                </Text>
              }
            >
              <CreateProjectModal />
              </Empty>
          </Space>
        </main>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <Space size="middle" direction="vertical" align="center">
          <Title>
            Projects
          </Title>

          <Row gutter={[16, 16]}>
            {projects.map((project) => (
              <Col key={project.id}>
                <Card
                  title={project.name}
                  bordered={false}
                  className={styles.card}
                  actions={[
                    <Link
                      key='open-project'
                      href={`/project/${project.id}`}
                      passHref
                      legacyBehavior>
                      <Button type="link">View project</Button>
                    </Link>,
                  ]}
                >
                  <Descriptions>
                    <Descriptions.Item label="Default locale">
                      {project.defaultLocale}
                    </Descriptions.Item>
                  </Descriptions>

                  <Space wrap size={[0, 4]}>
                    {project.locales.map((locale) => (
                      <Tag key={locale.id}>
                        {locale.name}
                      </Tag>
                    ))}
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>

          <CreateProjectModal />
        </Space>
      </main>
    </div>
  );
}
